import { useEffect, useState } from "react";
import { QueryObserverResult } from "react-query";
import {
  GetOneResponse,
  IResourceComponentsProps,
  useInvalidate,
  useList,
  usePermissions,
} from "@pankod/refine-core";
import {
  Edit,
  Form,
  Input,
  Select,
  Tabs,
  TimePicker,
  Typography,
  useForm,
} from "@pankod/refine-antd";
import dayjs from "dayjs";

import AssetUpload from "components/assetUpload";
import OfferTable from "components/courses/offers";
import SectionTable from "components/courses/sectionTable";
import ResourceTable from "components/courses/resourceTable";
import { ICourseShow, IInstructor, IProductor, ITag } from "interfaces";
import { TEMPLATE_TRANSLATIONS } from "services/certificate";

const { Title } = Typography;

export const CourseEdit: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, queryResult } = useForm<ICourseShow>();
  const { data, isLoading } = queryResult as QueryObserverResult<GetOneResponse<ICourseShow>>;
  const { data: roles } = usePermissions();
  const invalidate = useInvalidate();
  const { data: productorsData } = useList<IProductor>({ resource: "productors" });
  const { data: instructorsData } = useList<IInstructor>({
    resource: "instructors",
    config: {
      pagination: {
        pageSize: 100,
      },
    },
  });
  const { data: tagsData } = useList<ITag>({
    resource: "tags",
    config: {
      pagination: {
        pageSize: 100,
      },
    },
  });
  const [duration, setDuration] = useState<number>(0);
  const durationFormat = 'HH:mm';

  const productors = () => productorsData ? productorsData.data : [];

  const instructors = () => instructorsData ? instructorsData.data : [];

  const tags = () => tagsData ? tagsData.data : [];

  useEffect(() => {
    if (data) {
      setDuration(data.data.duration);
    }
  }, [data])

  const changeDuration = (value: any) => {
    const { form } = formProps;
    const newDuration = value ? value.hour() * 60 + value.minute() : 0;

    setDuration(newDuration);
    form!.setFields([{ name: 'duration', value: newDuration }]);
  }

  if (!data || !roles) {
    return null;
  }

  const { data: record } = data as GetOneResponse<ICourseShow>;

  const onUpdate = () => {
    invalidate({
      resource: "courses",
      invalidates: ["detail"],
      id: record.id,
    });
  };

  return (
    <Edit isLoading={isLoading} saveButtonProps={saveButtonProps}>
      <Tabs defaultActiveKey="info">
        <Tabs.TabPane tab="Informação" key="info">
          <Form {...formProps} layout="vertical">
            <Form.Item
              label="Nome"
              name="name"
              required
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Descrição"
              name="description"
              required
            >
              <Input.TextArea rows={4} />
            </Form.Item>

            <Form.Item
              name="duration"
              hidden
              required
            >
              <Input value={duration} />
            </Form.Item>

            <div className="flex gap-4 flex-row">
              <Form.Item
                label="Slug"
                name="slug"
                required
              >
                <Input disabled />
              </Form.Item>

              <Form.Item
                label="Produtor"
                name="productor_id"
                required
              >
                <Select defaultValue={record.productor.id}>
                {productors().map(({ id, name }) => (
                  <Select.Option key={id} value={id}>{name}</Select.Option>
                ))}
                </Select>
              </Form.Item>

              <Form.Item
                label="Template do Certificado"
                name="certificate_template_key"
              >
                <Select defaultValue={null}>
                {Array.from(TEMPLATE_TRANSLATIONS).map(([value, name]) => (
                  <Select.Option key={value} value={value}>{name}</Select.Option>
                ))}
                </Select>
              </Form.Item>

              <Form.Item
                label="Duração (hh:mm)"
                required
              >
                <TimePicker
                  value={dayjs(`${Math.floor(duration / 60)}:${duration % 60}`, durationFormat)}
                  onChange={changeDuration}
                  format={durationFormat}
                />
              </Form.Item>
            </div>

            <Form.Item
              label="Instrutores"
              name="instructor_ids"
              required
            >
              <Select
                showSearch
                mode="multiple"
                defaultValue={record.instructors.map(({ id }) => id)}
                optionFilterProp="children"
              >
              {instructors().map(({ id, name }) => (
                <Select.Option key={id} value={id}>{name}</Select.Option>
              ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Tags"
              name="tag_ids"
              required
            >
              <Select
                showSearch
                mode="multiple"
                defaultValue={record.tags.map(({ id }) => id)}
                optionFilterProp="children"
              >
              {tags().map(({ id, name }) => (
                <Select.Option key={id} value={id}>{name}</Select.Option>
              ))}
              </Select>
            </Form.Item>

            <Title level={4}>Images</Title>
            <div className="flex flex-row gap-4">
              <Form.Item
                label="Header"
                name={["images", "header"]}
              >
                <AssetUpload
                  accept="image/png, image/jpeg"
                  type="course"
                  src={record.images.header}
                />
              </Form.Item>
              <Form.Item
                label="Card"
                name={["images", "card"]}
              >
                <AssetUpload
                  accept="image/png, image/jpeg"
                  type="course"
                  src={record.images.card}
                />
              </Form.Item>
            </div>
          </Form>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Ementa" key="ementa">
          <SectionTable course={record} onUpdate={onUpdate} />
          <ResourceTable reference={record} referenceKind='course' onUpdate={onUpdate} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Ofertas" key="ofertas">
          <OfferTable course={record} onUpdate={onUpdate} />
        </Tabs.TabPane>
      </Tabs>
    </Edit>
  );
};
