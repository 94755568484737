import { IResourceComponentsProps, CrudFilters, HttpError, useList } from "@pankod/refine-core";
import {
  Button,
  Col,
  EditButton,
  Form,
  FormProps,
  Input,
  List,
  Row,
  Select,
  Space,
  Table,
  TagField,
  TextField,
  useTable,
} from "@pankod/refine-antd";

import { ICourse, IProductor } from "interfaces";

interface IFilter {
  name: string;
  productor: string | null;
}

interface FilterFormProps {
  searchFormProps: FormProps<IFilter>;
}

const FilterForm: React.FC<FilterFormProps> = ({ searchFormProps }) => {
  const { data: productorsData } = useList<IProductor>({
    resource: "productors",
  });

  const productors = () => (
    productorsData ? productorsData.data : []
  );

  return (
    <Form layout="vertical" {...searchFormProps}>
      <Form.Item label="Nome" name="name">
          <Input placeholder="Python para iniciantes" />
      </Form.Item>
      <Form.Item label="Produtor" name="productor">
        <Select defaultValue={null}>
          <Select.Option value={null}>Todos</Select.Option>
          {productors().map(({ id, name }) => (
            <Select.Option key={id} value={id}>{name}</Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item>
          <Button htmlType="submit" type="primary">
              Filter
          </Button>
      </Form.Item>
    </Form>
  );
}

export const CourseList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps, searchFormProps } = useTable<ICourse, HttpError, IFilter>({
    onSearch: (params) => {
      const filters: CrudFilters = [];
      const { name, productor } = params;

      filters.push(
        {
          field: "name",
          operator: "eq",
          value: name,
        },
        {
          field: "productor",
          operator: "eq",
          value: productor,
        },

      );

      return filters;
    }
  });

  return (
    <Row gutter={[16, 16]}>
      <Col lg={6} xs={24}>
        <FilterForm searchFormProps={searchFormProps} />
      </Col>
      <Col lg={18} xs={24}>

        <List>
          <Table {...tableProps} rowKey="id">
            <Table.Column
              dataIndex="name"
              key="name"
              title="Nome"
              render={(value) => <TextField value={value} />}
            />
            <Table.Column
              dataIndex="slug"
              key="slug"
              title="Slug"
              render={(value) => <TextField value={value} />}
            />
            <Table.Column
              dataIndex="tags"
              key="tags"
              title="Tags"
              render={(tags) => tags.map((tag: any) => (
                <TagField value={tag.name} />
              ))}
            />
            <Table.Column
              dataIndex="productor"
              key="productor"
              title="Produtor"
              render={({ name }) => <TextField value={name} />}
            />
            <Table.Column<ICourse>
              title="Actions"
              dataIndex="actions"
              render={(_, record) => (
                <Space>
                  <EditButton hideText size="small" recordItemId={record.id} />
                </Space>
              )}
            />
          </Table>
        </List>
      </Col>
    </Row>
  );
};
